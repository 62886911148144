import React from 'react';
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

const Services = () => {
  return (
    <Layout>
      <SEO title="Services We Offer"/>

      <div className="relative bg-gray-50 overflow-hidden mb-12 ltn-service-bg">
        <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32 ">
          <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
            <div className="text-center">
              <h3
                className="text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none ">
                Projects We
                <br className="xl:hidden"/>
                <span className="text-blue-600"> Excel </span>
                At
              </h3>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Here are some projects that we're really good at. If you have something else on your mind,
                let us know.
              </p>
            </div>
          </main>
        </div>
      </div>

      <div className="md:max-w-3xl mx-auto mb-16">
        <div className="bg-white px-4 py-5 border-b border-gray-200">
          <div className="flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="flex">
              <div className="h-8 w-8 text-gray-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"/>
                </svg>
              </div>
              <h3 className="text-xl leading-6 font-medium text-gray-600 ml-3 mt-1">
                Developer Productivity
              </h3>
            </div>
            <div className="ml-2 mt-2 flex-shrink-0">
              <span className="inline-flex rounded-md">
                <Link
                  to={"/contact"}
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-indigo focus:border-blue-500 active:bg-blue-500"
                >
                  Get Started
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="px-4 py-5">
          <p>Enabling your developers to ship code with speed and safety is a competitive advantage. We can help you
            design or update systems that enable your developers to ship code faster and safer. From CI/CD systems,
            to
            auto-provisioning, we have you covered.</p>
          <div className="mt-5">
            <span
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-pink-800">
                DevOps
            </span>
            <span
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-pink-800">
                CI/CD
            </span>
          </div>
        </div>
      </div>

      <div className="md:max-w-3xl mx-auto mb-16">
        <div className="bg-white px-4 py-5 border-b border-gray-200">
          <div className="flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="flex">
              <div className="h-8 w-8 text-gray-600">
                  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" /></svg>
              </div>
              <h3 className="text-xl leading-6 font-medium text-gray-600 ml-3 mt-1">
                Container Management
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to={"/contact"}
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-indigo focus:border-blue-500 active:bg-blue-500"
                >
                  Get Started
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="px-4 py-5">
          <p>Container orchestration platforms like Kubernetes provide a great deal of benefit to the whole
            organization.
            They can reduce deployment errors, speed up deployment times and simplify infrastructure management. Our
            experts can help evaluate, design, and implement container systems such as Kubernetes.</p>
          <div className="mt-5">
            <span
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-pink-800">
                Docker
            </span>
            <span
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-pink-800">
                Kubernetes
            </span>
          </div>
        </div>
      </div>

      <div className="md:max-w-3xl mx-auto mb-16">
        <div className="bg-white px-4 py-5 border-b border-gray-200">
          <div className="flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="flex">
              <div className="h-8 w-8 text-gray-600">
                  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" /></svg>
              </div>
              <h3 className="text-xl leading-6 font-medium text-gray-600 ml-3 mt-1">
                Infra-as-Code Adoption
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to={"/contact"}
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-indigo focus:border-blue-500 active:bg-blue-500"
                >
                  Get Started
                </Link>
              </span>
            </div>
          </div>

        </div>
        <div className="px-4 py-5">
          <p>Adopting infrastructure-as-code technologies and practices can greatly improve your infrastructure’s
            reliability, security, and operations. Our team can help you bootstrap your transition to an
            infra-as-code company. Let us help you start out on the right path by adopting best practices and
            avoiding the pitfalls.
          </p>
          <div className="mt-5">
            <span
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-pink-800">
                Terraform
            </span>
          </div>
        </div>
      </div>

      <div className="md:max-w-3xl mx-auto mb-16">
        <div className="bg-white px-4 py-5 border-b border-gray-200">
          <div className="flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="flex">
              <div className="h-8 w-8 text-gray-600">
                  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" /></svg>
              </div>
              <h3 className="text-xl leading-6 font-medium text-gray-600 ml-3 mt-1">
                Cost Optimization
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to={"/contact"}
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-indigo focus:border-blue-500 active:bg-blue-500"
                >
                  Get Started
                </Link>
              </span>
            </div>
          </div>

        </div>
        <div className="px-4 py-5">
          <p>One of the risks of using cloud services is that costs can quickly increase if the right control systems
            are
            not in place. Our team can help you instrument these controls by evaluating all of your cloud accounts,
            create plans to reduce costs, and help you execute those plans.
          </p>
          <div className="mt-5">
            <span
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-pink-800">
                Lower Costs
            </span>
          </div>
        </div>
      </div>

      <div className="md:max-w-3xl mx-auto mb-16">
        <div className="bg-white px-4 py-5 border-b border-gray-200">
          <div className="flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="flex">
              <div className="h-8 w-8 text-gray-600">
                  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" /></svg>
              </div>
              <h3 className="text-xl leading-6 font-medium text-gray-600 ml-3 mt-1">
                Cloud Training
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to={"/contact"}
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-indigo focus:border-blue-500 active:bg-blue-500"
                >
                  Get Started
                </Link>
              </span>
            </div>
          </div>

        </div>
        <div className="px-4 py-5">
          <p>Every company has its own mix of technologies. Making sure your employees are skilled with those
            technologies is critical. With our partnership with Skillmix, we are able to develop custom training
            programs that will help your employees get certified for third-party solutions, and properly trained for
            internal systems.
          </p>
          <div className="mt-5">
            <span
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-pink-800">
                Skillmix
            </span>
          </div>
        </div>
      </div>

      <div className="md:max-w-3xl mx-auto mb-16">
        <div className="bg-white px-4 py-5 border-b border-gray-200">
          <div className="flex items-center justify-between flex-wrap sm:flex-no-wrap">
            <div className="flex">
              <div className="h-8 w-8 text-gray-600">
                  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" ><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" /></svg>
              </div>
              <h3 className="text-xl leading-6 font-medium text-gray-600 ml-3 mt-1">
                Governance & Compliance
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
            <span className="inline-flex rounded-md shadow-sm">
              <Link
                to={"/contact"}
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-indigo focus:border-blue-500 active:bg-blue-500"
              >
                Get Started
              </Link>
            </span>
            </div>
          </div>

        </div>
        <div className="px-4 py-5">
          <p>Cloud computing’s low-friction sign-up and pay as you go ability, presents a multitude of security
            challenges. It’s easy to make a mistake, that’s why it’s critical to get in control of your company’s
            cloud accounts from the start. Our team can help you review your accounts and put in proper security,
            governance, and compliance practices.
          </p>
          <div className="mt-5">
            <span
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-pink-800">
                Audit
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Services;
